import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="notfound content">
      <div className="container">
        <div className="row">
          <h1>Error 404: Page Not Found</h1>
          <p>This page was eaten by the cute doggo gang. Woof Woof!</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
